import facebook from '../../../../assets/abarth/icon/facebook.svg';
import facebook_small from '../../../../assets/abarth/icon/facebook_small.svg';
import instagram_small from '../../../../assets/abarth/icon/instagram_small.svg';
import instagram from '../../../../assets/abarth/icon/instagram.svg';
import linkedin from '../../../../assets/abarth/icon/linkedin.svg';
import linkedin_small from '../../../../assets/abarth/icon/linkedin_small.svg';
import tiktok from '../../../../assets/abarth/icon/tiktok.svg';
import tiktok_small from '../../../../assets/abarth/icon/tiktok_small.svg';
import twitter from '../../../../assets/abarth/icon/twitter.svg';
import twitter_small from '../../../../assets/abarth/icon/twitter_small.svg';
import youtube from '../../../../assets/abarth/icon/youtube.svg';
import youtube_small from '../../../../assets/abarth/icon/youtube_small.svg';
import abarthDefaultServiceIcon from '../../../../assets/abarth/icon/usedcars.svg';
import abarthPassengerCarsSalesIcon from '../../../../assets/abarth/icon/passengercarsales.svg';
import abarthServiceValetIcon from '../../../../assets/abarth/icon/valet.svg';
import abarthPassengerCarsServiceIcon from '../../../../assets/abarth/icon/service.svg';
import open from '../../../../assets/abarth/icon/open.svg';
import phone from '../../../../assets/abarth/icon/phone.svg';
import poi from '../../../../assets/abarth/icon/poi.svg';
import nextArrow from '../../../../assets/abarth/icon/nextArrow.svg';
import nextArrowDark from '../../../../assets/abarth/icon/nextArrowDark.svg';
import prevArrow from '../../../../assets/abarth/icon/prevArrow.svg';
import prevArrowDark from '../../../../assets/abarth/icon/prevArrowDark.svg';
import downArrow from '../../../../assets/abarth/icon/downArrow.svg';
import phoneIcon from '../../../../assets/abarth/icon/phoneIcon.svg';
import dealerLocatorArrow from '../../../../assets/abarth/icon/dealerLocatorArrow.svg';
import deleteIcon from '../../../../assets/abarth/icon/delete.svg';
import formDateInput from '../../../../assets/abarth/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/abarth/icon/form-time-input.svg';
import checkboxTick from '../../../../assets/abarth/icon/checkboxTick.svg';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import teamMemberPlaceholder from '../../../../assets/abarth/icon/teamMemberPlaceholder.svg';
import closeIconWhite from '../../../../assets/abarth/icon/close-icon-white.svg';
import accordionChevronWhite from '../../../../assets/abarth/icon/accordionChevronWhite.svg';
import docExtension from '../../../../assets/abarth/icon/extension.svg';

export const abarthAssets = Object.freeze({
  facebook,
  facebook_small,
  instagram,
  instagram_small,
  linkedin,
  linkedin_small,
  tiktok,
  tiktok_small,
  twitter,
  twitter_small,
  youtube,
  youtube_small,
  abarthDefaultServiceIcon,
  abarthPassengerCarsSalesIcon,
  abarthServiceValetIcon,
  abarthPassengerCarsServiceIcon,
  phone,
  poi,
  open,
  nextArrow,
  nextArrowDark,
  prevArrow,
  prevArrowDark,
  downArrow,
  phoneIcon,
  dealerLocatorArrow,
  deleteIcon,
  formDateInput,
  formTimeInput,
  checkboxTick,
  noImagePlaceholder,
  teamMemberPlaceholder,
  closeIconWhite,
  accordionChevronWhite,
  docExtension,
});
